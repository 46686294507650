import React from 'react'
import {Route, Switch} from "react-router";
import RouteEnum from "../constants/RouteEnum";
import Period from "./Period/Period";
import Bicycles from "./Bicycles/Bicycles";
import Accessoires from "./Accessoires/Accessoires";
import Accommodation from "./Accommodation/Accommodation";
import Form from "./Form/Form";
import PaymentOverview from "./PaymentOverview/PaymentOverview";
import InfoAction from "../stores/api/info/InfoAction";
import {connect} from "react-redux";
import AuthAction from "../stores/api/auth/AuthAction";
import AppAction from "../stores/app/AppAction";
import LocationsPopup from "./_components/Popups/LocationPopup";
import BicyclePopup from "./_components/Popups/BicyclePopup";
import AccessoirePopup from "./_components/Popups/AccessoirePopup";
import GeneralAction from "../stores/api/general/GeneralAction";
import CategoryAction from "../stores/api/category/CategoryAction";
import AccessoireConditionPopup from "./_components/Popups/AccessoireConditionPopup";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import ValidationPopup from "./_components/Popups/ValidationPopup";
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import Thanks from "./Thanks/Thanks";
import Processing from "./Processing/Processing";
import Failed from "./Thanks/Failed";
import InsurancePopup from "./_components/Popups/InsurancePopup";
import BicyclesMany from "./Bicycles/BicyclesMany";
import {reformatOldSlugs} from "../utilities/etc/reformatOldSlugs";
import {LandingsPage} from "./LandingsPage/LandingsPage";
import SubjectBar from "./_components/SubjectBar";
import Summary from "./_components/Summary";
import Loading from "./_components/Loading";
import BankTransfer from "./Thanks/BankTransfer";

const mapStateToProps = (state) => ({
    ...state.app,
    ...state.error,
    webshopData: state.info.infoData.detail
});

class ViewRouter extends React.Component {

    scrollToMyRef = () => window.scrollTo(0, this.props.myRef.current.offsetTop)


    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.toggleReset !== prevProps.toggleReset) {
            this.init();
        }
    }

    init() {
        let params = this.props.match.params
        let slug = params.shopId

        if (params.shopSlug) {
            slug += '/' + params.shopSlug
        }

        const formattedSlug = reformatOldSlugs(slug)

        if (formattedSlug === '4/bikeshopegmond') {

            window.location = './fietseninegmond'

        }

        if (formattedSlug !== slug) {
            this.props.history.push(formattedSlug)
            this.props.dispatch(AppAction.setSlug(formattedSlug));
        } else {
            this.props.dispatch(AppAction.setSlug(slug));
        }


        this.props.dispatch(AuthAction.connect()).then(() => {

            this.props.dispatch(InfoAction.detail()).then(() => {
                this.props.dispatch(GeneralAction.languageList())
                this.props.dispatch(GeneralAction.cityList())

                if (this.props.webshopData) {


                    this.props.dispatch(AppAction.formatWebshop()).then(() => {
                        this.props.dispatch(CategoryAction.list({
                            partnerId: this.props.webshop.partner_id,
                            type: 'accessoire',
                            language: this.props.language
                        }))

                        this.props.dispatch(AppAction.setDefaultPeriod()).then(() => {
                            this.props.dispatch(AppAction.setOpenedLocations())
                        })
                        this.props.dispatch(AppAction.formatLocations())
                    })
                }

            })
        })
    }

    render() {

        const popups = this.props.popup

        if (this.props.webshop && this.props.locations) {
            return (

                <div className={'c-client-wrapper g-client--' + this.props.webshopData?.webshops?.partner?.client_id}>


                    <Switch>
                        <Route path={'/:shopId/:shopSlug?/afhandeling/:rentaluuid?'}
                               history={this.props.history}
                               component={Processing}/>

                        <Route path={'/:shopId/:shopSlug?/bedankt'}
                               history={this.props.history}
                               component={Thanks}/>

                        <Route path={'/:shopId/:shopSlug?/overboeking'}
                               history={this.props.history}
                               component={BankTransfer}/>

                        <Route path={'/:shopId/:shopSlug?/mislukt'}
                               history={this.props.history}
                               component={Failed}/>

                        <Route path={'/:shopId/:shopSlug?/geannuleerd'}
                               history={this.props.history}
                               component={Failed}/>


                        <Route>

                            {!!this.props.resetMessage &&
                                <div className="o-container o-container--primary">
                                    <p style={{
                                        margin: '2rem 0px 0px',
                                        background: '#ff6868',
                                        color: '#fff',
                                        padding: '1rem 2rem',
                                        borderRadius: '0.5rem',
                                        textAlign: 'center'
                                    }}>{this.props.resetMessage}</p>
                                </div>}

                            {this.props.step === RouteEnum.Landing &&
                                <LandingsPage
                                    dispatch={this.props.dispatch}
                                    app={this.props}
                                />}

                            {this.props.step !== RouteEnum.Landing &&
                                <>
                                    {this.props.step !== RouteEnum.Thanks && <SubjectBar/>}

                                    <Summary/>

                                    <ReactCSSTransitionGroup
                                        transitionName="step"
                                        transitionEnterTimeout={800}
                                        transitionLeave={false}>
                                        {this.props.step === RouteEnum.Period ? <Period
                                            scroll={this.scrollToMyRef}/> : null}{this.props.step === RouteEnum.Bicycles &&
                                        <>{!this.props.webshop.alternate_layout ? <Bicycles scroll={this.scrollToMyRef}/> :
                                            <BicyclesMany scroll={this.scrollToMyRef}/>}</>}
                                        {this.props.step === RouteEnum.Accessoires ?
                                            <Accessoires scroll={this.scrollToMyRef}/> : null}
                                        {this.props.step === RouteEnum.Accommodation ?
                                            <Accommodation scroll={this.scrollToMyRef}/> : null}
                                        {this.props.step === RouteEnum.Form ? <Form scroll={this.scrollToMyRef}/> : null}
                                        {this.props.step === RouteEnum.PaymentOverview ?
                                            <PaymentOverview history={this.props.history}
                                                             scroll={this.scrollToMyRef}/> : null}
                                        {popups.location.show && <LocationsPopup/>}
                                        {popups.bicycle.show && <BicyclePopup/>}
                                        {popups.insurance.show && <InsurancePopup/>}
                                        {popups.accessoire.show && <AccessoirePopup/>}
                                        {popups.accessoireCondition.show && <AccessoireConditionPopup/>}
                                        {!this.props.validation.valid && <ValidationPopup/>}
                                        {this.props.error && <>Error</>}


                                    </ReactCSSTransitionGroup>

                                </>}

                        </Route>

                    </Switch>

                </div>
            )
        } else {
            return (
                <div className={'c-loader'} key={'bicycle-loader'}
                     style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
                    <Loading/>
                </div>
            )
        }

    }

}

export default connect(mapStateToProps)(ViewRouter);
