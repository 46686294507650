import React from 'react'
import {connect} from "react-redux";
import AppAction from "../../../stores/app/AppAction";
import ChangeAccessoireNumber from "../../../utilities/dispatchers/ChangeAccessoireNumber";
import DisplayItemName from "../../../utilities/displayers/DisplayItemName";
import DisplayItemAvailability from "../../../utilities/displayers/DisplayItemAvailability";
import DisplayPrice from "../../../utilities/displayers/DIsplayPrice";
import Translate from "../../../utilities/etc/Translate";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel';

const mapStateToProps = (state) => ({
    ...state.app,
    info: state.info,
    cart: state.shoppingCart
});


class AccessoireItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        }

    }

    componentDidMount() {
        this.setState({
            loaded: true
        })
    }

    render() {


        const data = this.props.data

        let className = 'c-products__single'

        if (data.inactive) {

            if (!this.props.webshop.blur_items) {
                return null;
            }

            className += ' c-products__single--inactive'
        }

        return (

            <div className={className} key={data.id + '-accessoire'}>

                <div className="c-products__single__info">
                    <div className="c-products__single__info__primary">
                        <h4>{DisplayItemName(data)}</h4>
                        <div className="c-products__single__info__primary__wrapper">
                            <div className="c-products__single__amount">
                                <button className="minus" style={this.props.webshop.styles.dark} onClick={() => {
                                    ChangeAccessoireNumber(data, -1, this.props.dispatch)
                                }}><i className="icon icon-minus"/></button>
                                <span>{data.selected}</span>
                                <button className="plus" style={this.props.webshop.styles.dark} onClick={() => {
                                    ChangeAccessoireNumber(data, 1, this.props.dispatch)
                                }}><i className="icon icon-plus"/></button>
                            </div>
                            <span className="c-products__single__price">{DisplayPrice(data.price)}
                                <small>{Translate('p/s', this.props.language)}</small>
                            </span>
                        </div>
                    </div>
                    <div className="c-products__single__info__secondary">
                        {!!data.images &&
                        <div className="c-products__single__media c-bicycle-carousel">
                            <Carousel ref={(el) => (this.Carousel = el)} showArrows={false}
                                      showThumbs={false} showStatus={false} infiniteLoop={true}
                                      animationHandler={"fade"} swipeable={false}>
                                {data.images.map((image) => (
                                    <img src={image}/>
                                ))}
                            </Carousel>
                        </div>
                        }
                        <button onClick={() => this._clickMoreInfo(data.id)}
                                className="c-products__single__modal-trigger">
                            <i className="icon icon-info"/>
                            <span>{Translate('informatie', this.props.language)}</span>
                        </button>
                    </div>
                </div>
                {data.locations &&
                <>
                    <div className="availability-header"
                         style={this.props.webshop.styles.light}>{Translate('Beschikbaarheid', this.props.language)}</div>
                    <div className="c-products__single__locations">

                        {Object.keys(data.locations).map((locationId) => {
                            const location = this.props.locations[locationId]
                            location.accessoireData = data.locations[locationId]

                            let className = 'c-products__single__locations__single'

                            if (location.excluded || location.accessoireData.available === 0) {

                                if (!this.props.webshop.blur_locations) {
                                    return null;
                                }

                                className += ' is-deactivated'
                            }

                            return (
                                <div className={className} key={locationId + '- ' + data.id + '-accessoire-location'}>


                                    <div className="c-products__single__locations__single__wrapper">

                                        <button onClick={() => this._clickLocation(locationId)}><i
                                            className="icon icon-pin"/></button>

                                        <div className="c-products__single__locations__single__name">
                                            <span>{location.name}</span>
                                        </div>

                                    </div>

                                    <div className="c-products__single__locations__single__amount"
                                         style={{minWidth: '5rem'}}>
                                        {!!this.props.info?.infoData?.detail?.webshops?.item_icon &&
                                        <img style={{width: '2.5rem', marginRight: '0.5rem'}}
                                             src={this.props.info?.infoData?.detail?.webshops?.item_icon}/>
                                        }

                                        {!this.props.info?.infoData?.detail?.webshops?.item_icon &&
                                        <i className="icon icon-bicycle"/>
                                        }

                                        <small>{DisplayItemAvailability(location.accessoireData.available)}</small>
                                    </div>

                                </div>

                            )
                        })}
                    </div>
                </>
                }
            </div>
        )
    }

    _clickLocation(locationId) {
        this.props.dispatch(AppAction.changeLocationPopup({
            show: true,
            id: locationId
        }))
    }

    _clickMoreInfo(accessoireId) {
        this.props.dispatch(AppAction.changeAccessoirePopup({
            show: true,
            id: accessoireId
        }))
    }


}

export default connect(mapStateToProps)(AccessoireItem);
