import React from 'react'
import SummaryPeriod from "./SummaryPeriod";
import SummaryTotals from "./SummaryTotals";
import SummaryDelivery from "./SummaryDelivery";
import SummaryAccommodation from "./SummaryAccommodation";
import {connect} from "react-redux";
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

const mapStateToProps = (state) => ({
    ...state.app,
});

class Summary extends React.Component {

    render() {

        const summary = this.props.summary

        // Evaluate the basic conditions of the render logic
        const recurringEnabled = this.props.webshop.recurring_enabled ?? 0;
        const hasPeriod = summary.period;
        const hasBicyclesOrAccessoires = summary.bicycles || summary.accessoires;
        const canShowAccommodation = hasBicyclesOrAccessoires && summary.accommodation;
        const canShowDelivery = (canShowAccommodation || recurringEnabled) && summary.delivery && this.props.webshop.type.code !== 'registration';

        return (
            <div className="o-container o-container--primary">
                <div className={'c-summaries'}>
                    <ReactCSSTransitionGroup
                        transitionName="step"
                        transitionEnterTimeout={800}
                        transitionLeave={false}>
                        {hasPeriod && <SummaryPeriod/>}
                        {hasPeriod && hasBicyclesOrAccessoires && <SummaryTotals/>}
                        {hasPeriod && canShowAccommodation && <SummaryAccommodation/>}
                        {hasPeriod && canShowDelivery && <SummaryDelivery/>}
                    </ReactCSSTransitionGroup>
                </div>
            </div>
        )
    }


}

export default connect(mapStateToProps)(Summary);
