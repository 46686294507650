import React from 'react'
import {connect} from "react-redux";
import RouteEnum from "../../../constants/RouteEnum";
import AppAction from "../../../stores/app/AppAction";
import DisplayItemName from "../../../utilities/displayers/DisplayItemName";
import DisplayPrice from "../../../utilities/displayers/DIsplayPrice";
import Translate from "../../../utilities/etc/Translate";
import DisplayDecimal from "../../../utilities/displayers/DisplayDecimal";
import PaymentOptions from "./PaymentOptions";
import {useAppSelector} from "../../../stores/hooks";

const mapStateToProps = (state) => ({
    ...state.app,
    shoppingCart: state.shoppingCart,
    rental: state.rental.rentalData.update,
    paymentProvider: state.payment.paymentMethods.provider,
});

const Products = (props) => {

    const insuranceIcon = '/images/insurance-icon.svg'
    const deliveryIcon = '/images/tilia-truck-square-black.svg'
    const pickupIcon = '/images/tilia-return-truck-square-black.svg'
    const {infoData} = useAppSelector(state => state.info)
    const shopIsTypeRecurring = !!infoData.detail.webshops?.webshop?.recurring_enabled ?? false

    if (props.rental && props.webshop.type.code !== 'registration') {

        let discount = 0;

        if (!!props.periodDiscount) {
            discount = props.periodDiscount
        } else {

            if (props.webshop.default_discount && props.webshop.discount_percentage > 0) {
                discount = props.webshop.discount_percentage
            } else {
                if (props.delivery.deliveryPickupLocation && props.delivery.deliveryPickupLocation.address && props.delivery.deliveryPickupLocation.address.discount_percentage) {
                    discount = props.delivery.deliveryPickupLocation.address.discount_percentage
                }
            }
        }

        return (
            <div className="c-order-overview__products">

                <header>
                    <h3>{Translate('Uw *item_plural* & accessoires', props.language)}</h3>
                    <button onClick={() => props.dispatch(AppAction.switchAppStep(RouteEnum.Bicycles))}
                            className="btn c-order-overview__edit">{Translate('Wijzigen', props.language)}
                    </button>
                </header>

                <div className="c-order-overview__products__container">

                    {props.rental.rentalItems && props.rental.rentalItems.map((item) => {

                        if (item.type === 'category' || item.type === 'insurance' || item.type === 'delivery' || item.type === 'pick_up') {

                            const categoryData = {
                                    ...item.category
                                }

                            let itemImage = null;

                            if (item.type === 'category') {
                                itemImage = categoryData.image
                            } else if (item.type === 'insurance') {
                                itemImage = insuranceIcon
                            } else if (item.type === 'delivery') {
                                itemImage = deliveryIcon
                                categoryData.name = Translate('Bezorging', props.language)
                            } else if (item.type === 'pick_up') {
                                itemImage = pickupIcon
                                categoryData.name = Translate('Ophalen', props.language)
                            }

                            return (
                                <div key={'rental-item-' + item.id} className="c-order-overview__products__single">
                                    <div className="c-order-overview__products__single__container">
                                        <div className="c-order-overview__products__single__media">
                                            <img src={itemImage} alt=""/>
                                        </div>
                                        <span
                                            className="c-order-overview__products__single__amount">{item.quantity}x</span>
                                        <span
                                            className="c-order-overview__products__single__description">{DisplayItemName(categoryData)}</span>
                                    </div>
                                    <span
                                        className="c-order-overview__products__single__price">{DisplayPrice(item.totalPrice)}</span>

                                </div>
                            )
                        } else {
                            return null
                        }
                    })}

                    <div style={{borderTop: '1px solid #DEE5F0', margin: '2rem 0'}}/>

                    {props.rental.rentalItems.map((item) => {
                        if (item.type === 'deposit') {
                            return (
                                <div key={'deposit-item-' + item.id} className="c-order-overview__products__single">
                                    <div className="c-order-overview__products__single__container">

                                        <span
                                            className="c-order-overview__products__single__amount">{item.quantity}x </span>
                                        <span
                                            className="c-order-overview__products__single__description">{item.description}</span>
                                    </div>
                                    <span
                                        className="c-order-overview__products__single__price">{DisplayPrice(item.totalPrice)}</span>
                                </div>
                            )
                        } else {
                            return null
                        }
                    })}

                    {props.paymentProvider === 'ccv' || (shopIsTypeRecurring && props.paymentProvider === 'pay')
                        ? <PaymentOptions/>
                        : <></>
                    }

                    <div className="c-order-overview__total">
                        <span>{Translate('Totaalbedrag (incl. 21% BTW)', props.language)}</span>
                        <span>{DisplayPrice(props.rental.subTotalPrice)}</span>
                    </div>

                    {!!props.rental.totalDiscount &&
                        <div style={{paddingTop: 0}} className="c-order-overview__total">
                            <span>{DisplayDecimal(discount)}% {Translate('Korting', props.language)}</span>
                            <span>{DisplayPrice(props.rental.totalDiscount)}</span>
                        </div>
                    }

                    <div style={{paddingTop: 0}} className="c-order-overview__total">
                        <span>{Translate('Totaal te betalen', props.language)}</span>
                        <span>{DisplayPrice(props.rental.totalPrice)}</span>
                    </div>

                </div>
            </div>
        )
    } else return null


}

export default connect(mapStateToProps)(Products);
